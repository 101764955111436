import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/error404.css"; // Assuming you will write the CSS in a separate file.

const Error404 = () => {
  return (
    <div className="error-container">
      <img
        src="https://res.cloudinary.com/dilc7oqpu/image/upload/v1729512766/Innkeeper%20App/error404_design_gl1tyw.png"
        alt="404 - Page Not Found"
        width={300}
        height={300}
        className="error-image"
      />
      <h1>Oops! Page Not Found</h1>
      <p>We can't seem to find the page you're looking for.</p>
      <Link to="/" className="go-home-btn">
        Go Back to Home
      </Link>
    </div>
  );
};

export default Error404;
