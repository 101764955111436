import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import "./application-form.css";

function ApplicationForm() {
  return (
    <Container fluid className="bg-light p-2 application-form">
      <Row className="justify-content-center">
        <h2>Application Form</h2>

        <Col md={6} className="p-4">
          <img
            src="https://res.cloudinary.com/dilc7oqpu/image/upload/v1743167603/Innkeeper%20App/Microsoft_34_qtz97j.jpg"
            alt="Application form illustration"
            className="img-fluid"
            height={300}
          />
        </Col>
        <Col md={6} className="p-4">
          <p style={{ fontSize: "13px" }}>
            We’d love you to show your interest by applying here for free.
            Please fill out this form.
          </p>
          {/* Embed Google Form */}
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSd2Z9J8Cr5wUzO7SQQ_-VCLwkgktrIj_ZHuWL6mXqRuBHEuYg/viewform?embedded=true"
            width="100%" // Use 100% for responsiveness
            height="650"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="Application Form" // Add a title for accessibility
          >
            Loading…
          </iframe>
        </Col>
      </Row>
    </Container>
  );
}

export default ApplicationForm;
