import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

// Main pages
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Apply from "./pages/Apply";
import NoPage from "./pages/NoPage"; // For Error 404 Page

// Individual Courses
import Frontend from "./pages/courses/Frontend.jsx";
import CyberSecurity from "./pages/courses/CyberSecurity.jsx";
import DataAnalysis from "./pages/courses/DataAnalysis.jsx";
import DataScience from "./pages/courses/DataScience.jsx";
import DevOps from "./pages/courses/DevOps.jsx";
import DigitalMarketing from "./pages/courses/DigitalMarketing.jsx";
import ProductDesign from "./pages/courses/ProductDesign.jsx";
import ProductManagement from "./pages/courses/ProductManagement.jsx";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Main pages */}
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="apply-now" element={<Apply />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />

          {/* Courses Route */}
          <Route path="courses/frontend-development" element={<Frontend />} />
          <Route path="courses/cybersecurity" element={<CyberSecurity />} />
          <Route path="courses/data-science" element={<DataScience />} />
          <Route path="courses/data-analysis" element={<DataAnalysis />} />
          <Route path="courses/devops" element={<DevOps />} />
          <Route
            path="courses/digital-marketing"
            element={<DigitalMarketing />}
          />
          <Route path="courses/product-design" element={<ProductDesign />} />
          <Route
            path="courses/product-management"
            element={<ProductManagement />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
