import React from "react";
import Navbar from "../components/TopNavbar";
import Hero from "../components/About/AboutHero";
import Partners from "../components/LandingPage/Partners";
import LearningTrack from "../components/About/LearningTrack";
import FAQs from "../components/LandingPage/Faqs";
import Footer from "../components/Footer";
import TalentsCompany from "../components/LandingPage/TalentsCompany";
import OurTeam from "../components/About/OurTeam";
import Testimonials from "../components/LandingPage/Testimonials";
import Contact from "../components/LandingPage/Contact";

const About = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Partners />
      <LearningTrack />
      <TalentsCompany />
      <OurTeam />
      <FAQs />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
};

export default About;
