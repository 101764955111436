import React from "react";
import { Container, Row, Col, Accordion, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../LandingPage/faqs.css";
import { Link } from "react-router-dom";

const FAQs = () => {
  const faqs = [
    {
      question: "Are Innkeeper's courses online or in-class?",
      answer:
        "Innkeeper offers both online and in-class courses. Online courses can be accessed from anywhere with an internet connection, while in-class courses are conducted at our Hackerspace in Magodo, Lagos.",
    },
    {
      question: "How are the courses taught?",
      answer:
        "Our courses are taught using a combination of content delivery, reading materials, and interactive elements such as quizzes and hands-on projects. These classes are instructor-led for a comprehensive learning experience.",
    },
    {
      question:
        "Are there any additional materials I need to be successful in the course?",
      answer:
        "In most cases, students will need access to a laptop and a stable internet connection. Some specific courses may also require additional materials or software.",
    },
    {
      question: "What is your teaching methodology?",
      answer:
        "Innkeeper employs a blended learning approach combining self-paced learning with instructor-led practical hands-on projects, ensuring a well-rounded educational experience.",
    },
    {
      question: "What qualifications do your instructors have?",
      answer:
        "All of our instructors are highly qualified professionals with extensive experience in the tech industry. They have a proven track record of success in their respective fields.",
    },
    {
      question: "How long is each course?",
      answer:
        "The duration of our courses varies depending on the specific program. Typically, our courses range from one month to several months, depending on the depth and scope of the curriculum.",
    },
    {
      question: "Do you offer any certification after the program?",
      answer:
        "Yes, upon successful completion of our courses, students will receive a certificate of completion, recognizing their achievement in the program.",
    },
    {
      question: "What is the format of the certificates offered by Innkeeper?",
      answer:
        "Our certificates are digital, which means they can easily be viewed online. However, upon request, they can also be printed for students who prefer a physical copy.",
    },
    {
      question: "What are the available payment options?",
      answer:
        "We understand that everyone has different financial preferences. To make your program accessible to a wider range of aspiring tech professionals, we offer several payment options.",
    },
    {
      question: "Can I get a professional certification for these courses?",
      answer:
        "Yes, you can get a professional certification for our courses, but the payment is done independently of the training fee.",
    },
    {
      question: "How do I become a part of Innkeeper Alumni?",
      answer:
        "The Innkeeper Alumni is a thriving community of individuals who have gone through the Innkeeper training academy. To be a part of our Innkeeper fellows, you must have completed any of our training programs.",
    },
    {
      question: "What is the average duration of training at Innkeeper?",
      answer:
        "The average training ranges from between 10 weeks to 20 weeks depending on the course being enrolled for. Kindly view the Training Calendar for more details.",
    },
  ];

  const halfLength = Math.ceil(faqs.length / 2);
  const firstColumn = faqs.slice(0, halfLength);
  const secondColumn = faqs.slice(halfLength);

  return (
    <div style={{ backgroundColor: "#FEF8EE", padding: "20px 10px" }}>
      <Container className="px-0">
        <Row>
          <Col>
            <h4>FAQs</h4>
            <p>
              Everything you need to know about the Innkeeper. Can’t find the
              answer you’re looking for? Please contact us
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Accordion>
              {firstColumn.map((faq, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{faq.question}</Accordion.Header>
                  <Accordion.Body>{faq.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
          <Col md={6}>
            <Accordion>
              {secondColumn.map((faq, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{faq.question}</Accordion.Header>
                  <Accordion.Body>{faq.answer}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={8} className="d-flex flex-column">
            <h3>Still have questions?</h3>
            <p>
              Can’t find the answer you’re looking for? Please chat to our
              friendly team.
            </p>
          </Col>
          <Col
            md={4}
            className="d-flex justify-content-md-end align-items-center"
          >
            {" "}
            <Link to="https://wa.me/2348137011735" target="_blank">
              <Button className="faq-btn">Contact us</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FAQs;
