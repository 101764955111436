import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./courseitems.css";

const CourseCatalog = () => {
  const [courses, setCourses] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("All");

  useEffect(() => {
    AOS.init({ duration: 1000 });
    axios
      .get("/json/courseDetails.json")
      .then((response) => setCourses(response.data))
      .catch((error) => console.error("Error fetching courses:", error));
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleCategoryChange = (event) => {
    setFilterCategory(event.target.value);
  };

  const filteredCourses = Object.fromEntries(
    Object.entries(courses)
      .filter(
        ([category]) => filterCategory === "All" || filterCategory === category
      )
      .map(([category, courseList]) => {
        const filteredList = courseList.filter((course) =>
          course.title?.toLowerCase().includes(searchTerm.trim())
        );
        return filteredList.length > 0 ? [category, filteredList] : null;
      })
      .filter(Boolean)
  );

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <h4>Our Courses</h4>
          <p style={{ fontSize: "13px" }}>
            Have you been thinking of courses to enroll in this year? We have
            you in mind!
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Search courses..."
            onChange={handleSearch}
          />
        </Col>
        <Col md={6}>
          <Form.Select onChange={handleCategoryChange}>
            <option value="All">All Categories</option>
            {Object.keys(courses).map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      {Object.entries(filteredCourses).map(([category, courseList]) => {
        const sliderSettings = {
          dots: courseList.length > 3, // Only show dots if more than 3 courses
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 2,
          responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2 } },
            { breakpoint: 768, settings: { slidesToShow: 1 } },
          ],
        };

        return (
          <div key={category} data-aos="fade-up">
            <h3 className="mt-4">{category}</h3>
            <Slider {...sliderSettings}>
              {courseList.map((course, index) => (
                <Card key={index} className="m-2 course-card">
                  <Card.Img
                    variant="top"
                    className="card-img"
                    src={course.image}
                    alt={course.title}
                  />
                  <Card.Body>
                    <Card.Title className="course-title">
                      {course.title}
                    </Card.Title>
                    <Card.Text className="course-desc">
                      {course.description}
                    </Card.Text>
                    <p className="course-info">
                      <strong>Duration:</strong> {course.duration || "N/A"}
                    </p>
                    <p className="course-info">
                      <strong>Price:</strong> ₦
                      {course.price?.toLocaleString() || "N/A"}
                    </p>
                    <hr />
                    <p className="course-info">
                      <strong>Mode:</strong>
                      {course.modes?.map((mode, modeIndex) => (
                        <Button
                          key={modeIndex}
                          variant="outline-primary"
                          className="learning-mode-button"
                        >
                          {mode}
                        </Button>
                      ))}
                    </p>
                    <p className="course-reviews">
                      <strong>Reviews:</strong> {course.reviews || "No Reviews"}{" "}
                      ⭐⭐⭐⭐
                    </p>
                  </Card.Body>
                </Card>
              ))}
            </Slider>
          </div>
        );
      })}
    </Container>
  );
};

export default CourseCatalog;
